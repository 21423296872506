import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { styled } from 'twin.macro';
import React from 'react';

const PageNotFoundStyled = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  min-width: 100%;
  min-height: 100%;
  flex-grow: 1;
  background-image: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgb(0, 50, 92) 100%);
  font-family: Open Sans;
  padding: 1rem;
  color: #ffffff;
  + footer {
    display: none;
  }
  .title {
    font-size: 80px;
    text-decoration: none solid rgb(255, 255, 255);
    /* line-height: 36px; */
    text-align: center;
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.6);
    font-weight: bold;
    margin-bottom: 60px;
  }
  .description {
    font-size: 40px;
    text-decoration: none solid rgb(255, 255, 255);
    line-height: 60px;
    text-align: center;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
    font-weight: 300;
  }
  .btn {
    display: inline-block;
    font-size: 23px;
    color: #404040;
    text-decoration: none solid rgb(64, 64, 64);
    text-align: center;
    border-radius: 55px;
    background-color: #ffffff;
    box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.2);
    font-weight: bold;
    padding: 13px 20px;
    margin-top: 42px;
  }
  @media (min-width: 768px) {
    justify-content: center;
    padding-left: 20%;
    padding-right: 20%;
    height: calc(100vh - 85px);
  }
`;

function PageNotFound() {
  const { t } = useTranslation();
  return (
    <PageNotFoundStyled className="page-not-found">
      <h2 className="title">{t('page404.title')}</h2>
      <p className="description">{t('page404.description')}</p>
      <Link className="btn-to-home btn" to="/">
        {t('page404.btnToHomePage')}
      </Link>
    </PageNotFoundStyled>
  );
}

export default PageNotFound;
